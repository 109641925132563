.modal {
    position: fixed;
    z-index: 1;
    left: 100px;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    position: relative;
    background-color: #fefefe;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    text-align: center;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    font-weight: bold;
    color: rgb(247, 7, 7);
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .preview {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
  
