

.paperContainer {
    background-image: url(../../src/assets/images/login-illustration.svg);
    background-size: cover;
    width: inherit;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    /* border:5px solid red; */
}

.box {
    /* border:5px solid blue; */
    margin-top: 100px;
    margin-left: 100px;
}

.griditem {
    display: flex;
    justify-content: flex-end;
}


@media only screen and (max-width: 600px) {
    .paperContainer {
        height: 50vh;
    }

    .box {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
}