.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 25px;
  width: 90%;
  margin: auto;
}

.header {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header::-webkit-scrollbar {
  height: 7px;
}

.header::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c7c7c7;
}

.header .section {
  padding: 10px;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.header .active {
  border-bottom: 2px solid #239ce2;
  color: #239ce2;
}

.body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 0;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;

}

.detail .row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.detail .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail .row>div {
  flex: 1;
}
.subprojectcls{
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.subContainer{
  padding: 35px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

}

.ultag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}