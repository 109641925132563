.NotificationMain{
    width: 60%;
    margin: auto;
    padding: 20px;
    margin-top: 20px;
}

.NotificationCard{
        display: flex;
        margin-bottom: 10px;
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;     
}

.NotificationCard:hover,
.NotificationCard.hovered {
  background-color: #f0f0f0;
  cursor: pointer;
}

.InitialsAvatar{
    margin-right: 5px;
    align-items: center;
}

.NotificationText{
    margin: auto;
    justify-content: center;
}

.InitialsAvatarinner{
 background-color: rgb(145, 143, 143);
 color: white;
 border-radius: 50%;
 width: 45px;
 height: 45px;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 18px; 
 font-weight: bolder;
}


.NotificationBell{
    cursor: pointer;
    right: -30px;
    top : -10px
}
