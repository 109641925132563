.containerforgot{
    margin: auto;
     width: 100%;
     display: flex;
     /* border:5px solid green; */
   }
.imgmain{
    width: 40%;
    /* border:5px solid blue; */
    background-image: url(../../src/assets/images/login-illustration.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

}
.mainResume{
    width: 30%;
    border: "10px solid red";
    margin: auto;
    margin-top: 100px;
    padding: 50px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
  


.prev{
    border: "10px solid red";
    color: black;
    font-weight: 600;
    background-color: rgb(241, 247, 237);
    padding: 8px;
    border-radius: 5px;
  }
  
 
  .head{
    text-align: center;
    margin-bottom: 5px;
    padding: 3px;
  }
   
  .butt{
    margin-top: 7px;
    display: flex;
    flex-direction: column;
  }

  .textmar{
    margin-bottom: 15px;
  }

.formcontrol{
   margin: auto;
    width: 90%;
}

