.ag-theme-alpine {
  --ag-header-foreground-color: white !important;
  --ag-header-background-color: rgb(33, 80, 162) !important;

}
.ag-header-cell-label {
  justify-content: center;
}
.ag-header-center {
 padding-left: 95px;
  margin: auto;
}

.downloadButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 15px 0px;
}


